import React from "react";

import { PublicLayout } from "../../components/layouts/PublicLayout";
import { Login } from "../../containers/login/Login";

const LoginAdminPage = () => {
    return (
      <PublicLayout>
        <Login adminPage={true} />
      </PublicLayout>
    ) ;
}

export default LoginAdminPage ;
